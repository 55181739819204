import React from 'react';

interface IToggleButtonProps {
  className?: string;
  innerClassName?: string;
  state?: boolean;
  onClick?: () => void;
}

export const ToggleButton = ({
  className = '',
  innerClassName = '',
  state = false,
  onClick
}: IToggleButtonProps) => {
  const onToggle = () => {
    if (onClick) {
        onClick();
    }
  };

  return (
    <div
      onClick={() => onToggle()}
      className={`w-16 h-10 flex items-center bg-gray-300 rounded-full p-1 ${className} duration-300 ease-in-out ${
        state ? 'bg-green-400' : ''
      }`}
    >
    
      <div
        className={`bg-white w-8 h-8 rounded-full shadow-md ${innerClassName} transform ${
          state ? 'translate-x-6' : ''
        } duration-300 ease-in-out `}
      ></div>
    </div>
  );
};
